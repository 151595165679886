<template>
<!--  <nav>-->
<!--    <router-link to="/">Home</router-link> |-->
<!--    <router-link to="/about">About</router-link>-->
<!--  </nav>-->
  <a-config-provider :locale="zhCN">
    <router-view/>
    <div class="loading" v-if="$store.state.loading !== 0"><a-spin size="large" /></div>
  </a-config-provider>
</template>

<script lang="ts">
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { defineComponent, onMounted } from 'vue';
import { useStore } from 'vuex';
import { storage } from '@/utils/storage'
import { loanApplyProduct } from "@/api/loan";
dayjs.locale('zh');
export default defineComponent({
  setup() {
    const store = useStore()
    const key = process.env.VUE_APP_COOKIE_KEY
    onMounted (() => {
      let user = storage.get(process.env.VUE_APP_COOKIE_KEY)
      if (user) {
        store.commit('SET_USER', user)
      }
    })
    return {
      zhCN
    }
  }
});
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1111;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
