export const copy = (text :string) => {
    if (navigator.clipboard) {
        // clipboard api 复制
        navigator.clipboard.writeText(text)
    } else {
        const textarea = document.createElement('textarea')
        document.body.appendChild(textarea)
        // 隐藏此输入框
        textarea.style.position = 'fixed'
        textarea.style.clip = 'rect(0 0 0 0)'
        textarea.style.top = '10px'
        // 赋值
        textarea.value = text
        // 选中
        textarea.select()
        // 复制
        document.execCommand('copy', true)
        // 移除输入框
        document.body.removeChild(textarea)
    }
}

export const currentDate = () => {
    const date = new Date()
    const year = date.getFullYear() + '年'
    const month = (date.getMonth() + 1) + '月'
    const day = date.getDate() + '日'
    return `${[year, month, day].join('')}`
}

export const formatName = (n :string) => {
    if (n) {
        return n.replace(/^(.).*(.)$/, "$1*$2")
    }
}

export const formatPhone = (n :string) => {
    if (n) {
        return n.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
    }
}

export const formatIdNo = (n: string) => {
    if (n) {
        return n.replace(/^(.{5})(?:\d+)(.{4})$/,  "$1******$2")
    }
}

export const filterDict = (options: any, state: string | number) => {
    for (let item of options) {
        if (item.value == state) {
            return item.label
        }
    }
    return '-'
}

export const isPassword = (str: any) => {
    const patternArr = [
        /\d+/,
        /[a-z]+/,
        /[A-Z]+/,
        /.*[^A-Za-z0-9]+.*?/
    ]
    let count = 0
    for (let i in patternArr) {
        if (patternArr[i].test(str.toString() || '')) {
            count++
        }
    }
    return count >= 2 && /^\S{8,16}$/.test(str.toString() || '')
}

export const filterAmount = (value: any) => {
    if (value === '-') {
        return '-'
    }
    if (value || value === 0 || value === '0') {
        if (typeof value == 'string') {
            value = parseFloat(value)
        }
        return value.toLocaleString("zh", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    } else {
        return '-'
    }
}

export const filterPercent = (value: any) => {
    if (value === '-') {
        return value
    }
    if (value || value === 0 || value === '0') {
        if (typeof value == 'string') {
            value = parseFloat(value)
        }
        return (100 * value).toFixed(2) + '%'
    } else {
        return '-'
    }
}

export const filterAmountCurrency = (value: any, currency: any) => {
    if (value === '-') {
        return '-'
    }
    if (value || value === 0 || value === '0') {
        if (typeof value == 'string') {
            value = parseFloat(value)
        }
        return (currency ? currency : '¥') + value.toLocaleString("zh", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    } else {
        return '-'
    }
}
